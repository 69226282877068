import Layout from "../components/layout"
import Seo from "../components/seo"
import {useState} from "react";
import {Link} from "gatsby";
import React from 'react';
import authHeader from "../utils/auth-header";
import toast, {Toaster} from "react-hot-toast";
import LoadingSpinner from "../components/loading-spinner";

const ForgotPassword = () => {


    const [email, setEmail] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleChangePassword = (e) => {

        e.preventDefault();

        try {

            setSubmitDisabled(true);
            setLoading(true);

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    email: email,

                })
            };


            fetch(process.env.WASHCAR_API_URL + "/partners/get-password-reset-link", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    setSubmitDisabled(false);
                    setLoading(false)

                    if(resultData.status === 'Error'){
                        toast.error(resultData.alert.text)
                    }
                    else{
                        toast.success(resultData.alert.text);
                    }


                }) // set data for the number of stars



        } catch (err) {
            console.log(err);
        }


    };


    return (
        <Layout>

            <Seo title="Azimirsu paroli"/>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {loading ? <div className="pos-login"><LoadingSpinner /></div> : null}


            <section className="hero is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-centered">
                            <div className="column is-5-tablet is-4-desktop is-6-widescreen">
                                <div className="notification is-warning">Ievadiet e-pasta adresi ar kuru reģistrējāties un mēs jums nosūtīsim paroles atjaunošanas saiti.</div>
                                <form onSubmit={handleChangePassword} className={'box'}>

                                    <div className="field">
                                        <label className="label">E-pasts</label>
                                        <div className="control">
                                            <input required className="input" type="email" value={email}
                                                   onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="field is-grouped">
                                        <div className="control">
                                            <button disabled={submitDisabled} type="submit" className="button is-link">Nosūtīt paroles atjaunošanas saiti
                                            </button>
                                        </div>
                                    </div>

                                    <Link  className='' activeClassName="is-active" to="/">Atpakaļ uz sākumlapu</Link>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </Layout>
    )
}

export default ForgotPassword
